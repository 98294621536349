
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;600&display=swap');

.Baskervville {
  font-family: "Baskervville";
  font-size: 40px;
  font-weight: bold;
}

small {
  text-align: center;
  color: #fff;
  z-index: 2;
}

#target-element {
  height: 40px;
}

.w100 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}
.example {
  font-size: 16px;
}

.video-container video {
  position: absolute;
  top: 60px;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

#arrows {
  width: 100px;

  margin: 20px auto;
  display: flex;
}

video {
  border-radius: 20px;
  margin: 0px auto;
}

small img {
  padding: 5px;
}

.scroll {
  color: #fff;
  text-align: center;
  margin: 0px auto;
}

.case-content {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.soria {
  font-family: "Lora";
  font-size: 40px;
  font-weight: 400;
}

/* App.css */
.table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  color: #fff;
  border-collapse: collapse;
  padding-top: 30px;
}

.table-header {
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #fff;
}

.table-cell {
  
  padding-bottom: 10px;
  text-align: left;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .table {
    grid-template-columns: 1fr;
  }
}
.center {
  text-align: center;
  z-index: 2  ;
}

header img { margin: 0px auto;}

#root{
  background-color: #181818
}

.CaseStudies {
  
    display: inline-flex;
    width: 100vw;
    padding: 150px 150px 0px 150px;
}


header p {
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



/* Initial background color */
.fade-in {
  transition: opacity 5s ease-in;
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.realtive {
  position: relative;
}

.overlay {
  position: absolute;
  z-index: 99999px
  ;
}

.frame{      
  display: block;  /* iframes are inline by default */   
  height: 100vh;  /* Set height to 100% of the viewport height */   
  width: 100vw;  /* Set width to 100% of the viewport width */     
  border: none; /* Remove default border */
}

.html {

  color: #fff;
}
.container {
  padding: 0px 100px;
}

.grid2x2 {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.grid2x2 > div {
  display: flex; 
  flex-basis: calc(50% - 40px);  
  justify-content: center;
  flex-direction: column;
}
.grid2x2 > .box2 {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 2rem 1rem;
  color: #000;
  position: relative;
  height: 100vh;
  width: 100%;
  z-index: 4;
}

footer{
  height: 80vh;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 10px;
  padding: 15px;
  background-color: rgba(53,58,63,.4);
  border-radius: 20px;
  margin: 150px;
  
}

footer section {
  display: flex;
}

footer .top {
  justify-content: space-between;
  align-items: flex-start;
}

footer h1 {
  font-weight: normal;
  text-align: center;
}

footer .middle {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
}

footer .bottom {
  display: flex;
  justify-content: space-between;
}

header h1 {
  font-size: 100px !important;
  text-align: center;
  height: 0px !important;
}

nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  border: 1px solid #fff;
}
.namedpm {
  margin: 0;
}
h5 {
  margin-top: 0.5rem;
}

h1, h2, h3, h4, h5 {
  color: #fff ;
}

.namedpm {
  font-size: 100px;
}

.highlight {
  opacity: 1 !important;
} 


.mob {
  display: none !important;
}
.intro {
  width: 100%;
}

a { color: #fff; font-weight: normal; text-decoration: none; font-size: 20px;
padding-right: 20px; }

.open {
  color: #ffffff;
}


.fuse {
  margin: auto;
  width: 50px;
  height: 50px;
  color: white;
  animation: mymove 2s infinite;
}

@keyframes mymove {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

* {
  box-sizing: border-box;
}


.twitter {
  margin-right: 30px;
}



.logo {
  width: 340px;
}

.column > img {
  vertical-align: middle !important;
}

.column1 {
  margin-right: 300px;
}

li {
  color: #ffffff !important;
  display: flex;
  margin-right: 80px;
  font-size: 14px;
}

ul {
  display: inline-flex;
}

/* .column {
  float: left;
  width: 50%;
} */


html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}





body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background-color: #101010;
  background: radial-gradient(circle at bottom center, #212121 0%, #101010 80%);
  font-family: "Inter var", sans-serif;
  -webkit-font-smoothing: antialiased;
}



.caption {
  position: fixed;
  top: 0;
  right: 0;
  margin: 80px;
  color: white;
  font-size: 8em;
  font-weight: 100;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
}

#left {
  float: left !important;
}

.dot {
 
  position: sticky;
  top: 0px;
  max-width: 600px;
  padding: 0;
  padding: 80px;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1.5px;
  float: right;
}

.dot > p > a {
  color: darkgoldenrod;
  font-weight: bold;
  text-decoration: none;
}

.dot > h1 {
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: ffffff;
  font-size: 5em;
  font-weight: 100;
  line-height: 1em;
  margin: 0px;
  margin-bottom: 0.25em;
}

.dot > p 
{
  font-family: FarfetchBasisReg,Helvetica,Arial,sans-serif;
}

.dot > h2 {
  font-family: Nimbus, Helvetica, Arial, sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #ffffff;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4,.6,1) infinite;
  z-index: 2;
  font-size: 30px;
  padding-top: 20px;
}
@media only screen and (max-width: 480px) {

  header {
    height: 50vh !important;
  }

  header h1 {
    font-size: 50px !important;
  }

  .mobile {
    display: none !important;
  }

  footer {
    margin: 15px !important;
  }

  footer .middle {
    height: 38vh;
  }

  small a {
    font-size: 15px;
  }

  footer h1 {
    font-size: 15px;
  }

  footer 
  {
    height: 50vh;
  }

  .scroll {
    display: none !important;
  }

  .qrcode {
    display: none !important;
  }

  .App {
    width: 100% !important;
  }
  .soria {
    font-size: 20px;
  }

  h2 {
    font-size: 15px;
  }

  .CaseStudies {
    padding: 20px !important;
    margin: 0px auto !important;
  }

  video {
    height: 50vh !important;
    margin: 0px !important;
    width: 100% !important;
    
  }
  .card_card__M39cm {
    width: 15rem !important;
    padding: 0px !important;
    transform: scale(1.1) !important;
  }

  .table {
    display: none;
  }
  
  .css-doq0dk
  {
    width: 60vw !important;
    margin: 0px auto !important;
  }
}



